(function($) {

  // init main menu
    var $mainmenu = $('#menu-hoofdmenu');
    $mainmenu.addClass('collapsed');

  // Main navigation Hamburger toggler
  $('.menu-toggler').click(function(){
    if ( $mainmenu.hasClass('expanded') ) {
      $mainmenu.removeClass('expanded');
      $mainmenu.addClass('collapsed');
    } else {
      $mainmenu.addClass('expanded');
      $mainmenu.removeClass('collapsed');
    }
    $(this).toggleClass('active');
  });
})(jQuery);
